<template>
    <div class="daily-exposure-widget">
        <div class="row mb-3">
            <div class="col-12">
                <b-button variant="light" class="mr-auto" @click="onExport">
                    Export
                </b-button>
            </div>
        </div>
        <highcharts constructor-type="stockChart" :options="chartOptions" :style="`height: ${height};`"></highcharts>
    </div>
</template>

<script>
import { format, compareAsc } from 'date-fns';
import Highcharts from 'highcharts';
import stockInit from 'highcharts/modules/stock';
import { map, clone } from 'lodash';
import { exportCsv } from '@/lib/Exporting';

export default {
    name: 'daily-exposure',
    data() {
        return {
            chartOptions: {
                chart: {
                    zoomType: 'x',
                    type: 'column'
                },
                title: {
                    text: ''
                },
                xAxis: {
                    type: 'datetime'
                },
                yAxis: {
                    title: {
                        text: 'Leverage'
                    },
                    labels: {
                        overflow: 'justify'
                    },
                    opposite: false
                },
                tooltip: {
                    valueDecimals: 2
                },
                legend: {
                    enabled: true
                },
                credits: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        dataGrouping: {
                            enabled: false
                        }
                    }
                },
                rangeSelector: {
                    enabled: true,
                    verticalAlign: 'top',
                    x: 0,
                    y: 0
                },
                series: [
                    {
                        name: 'Max',
                        data: []
                    }
                ]
            },
            hasSetupListeners: false
        };
    },
    props: {
        height: {
            type: String,
            defaut: '400px'
        },
        account: {
            type: String,
            default: ''
        }
    },
    created() {
        stockInit(Highcharts);
    },
    mounted() {
        this.requests();
    },
    beforeDestroy() {
        this.$ws.off('reconnected', this.requests);
        this.$ws.off('dailyexposureleverage', this.onDailyExposure);
    },
    methods: {
        requests() {
            if(!this.hasSetupListeners) {
                this.$ws.on('reconnected', this.requests);
                this.$ws.on('dailyexposureleverage', this.onDailyExposure);
                this.hasSetupListeners = true;
            }

            let account = this.account;
            if(!account) {
                account = this.$store.state.activeAccount;
            }

            this.$ws.send({
                sessionID: 'NotImplemented',
                accountName: account,
                request: 'DailyExposureLeverage',
                args: {}
            });
        },
        onDailyExposure(event) {
            let row = {
                name: 'Max',
                data: []
            };

            let dates = Object.keys(event.response).map(function(date) {
                let split = date.split(' ')[0].split('/');
                return new Date(split[2], parseInt(split[1]) - 1, split[0]);
            }).sort(compareAsc);

            for(let date of dates) {
                let utc = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());

                let formattedDate = `${format(date, 'dd/MM/yyyy')} 00:00:00`;

                row.data.push([utc, parseFloat(event.response[formattedDate].max)]);
            }

            this.chartOptions.series = [row];
        },
        onExport() {
            let headers = {
                timestamp: 'Timestamp',
                exposure: 'Max Exposure'
            };

            let data = [];
            for(let dataPoint of this.chartOptions.series[0].data) {
                data.push({
                    timestamp: format(new Date(dataPoint[0]), 'yyyy-MM-dd HH:mm:ss'),
                    exposure: dataPoint[1]
                });
            }

            exportCsv(headers, data, 'DailyExposure');
        }
    }
}
</script>