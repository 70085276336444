<template>
    <div class="leverages-widget">
        <div class="row mb-3">
            <div class="col-12 d-flex align-items-center justify-content-end">
                <b-button variant="light" class="mr-auto" @click="onExport">
                    Export
                </b-button>
                <b-form inline @submit.prevent>
                    <label class="mr-sm-2" for="average-leverages-type-filter">Type:</label>
                    <b-form-select id="average-leverages-type-filter" class="mb-2 mr-sm-2 mb-sm-0" v-model="type" :options="filterOptions.type" @change="requests"></b-form-select>
                </b-form>
            </div>
        </div>
        <highcharts :options="chartOptions" :style="`height: ${height};`"></highcharts>
    </div>
</template>

<script>
import { compareAsc, format, subBusinessDays } from 'date-fns';
import { getColor } from '@/lib/ColorLookup';
import { map, clone } from 'lodash';
import { exportCsv } from '@/lib/Exporting';

export default {
    name: 'leverages',
    data() {
        return {
            chartOptions: {
                chart: {
                    zoomType: 'x',
                    type: 'area'
                },
                title: {
                    text: ''
                },
                xAxis: {
                    type: 'datetime'
                },
                yAxis: {
                    title: {
                        text: 'Leverage'
                    },
                    labels: {
                        overflow: 'justify'
                    },
                    opposite: false
                },
                tooltip: {
                    valueDecimals: 2
                },
                legend: {
                    enabled: true
                },
                credits: {
                    enabled: false
                },
                plotOptions: {
                    area: {
                        stacking: 'normal'
                    }
                },
                rangeSelector: {
                    enabled: true,
                    verticalAlign: 'top',
                    x: 0,
                    y: 0
                },
                series: []
            },
            type: 'Market',
            filterOptions: {
                type: [
                    {
                        value: 'Market',
                        text: 'Market'
                    },
                    {
                        value: 'Bucket',
                        text: 'Bucket'
                    },
                    {
                        value: 'Factor',
                        text: 'Factor'
                    }
                ]
            },
            hasSetupListeners: false
        };
    },
    props: {
        height: {
            type: String,
            defaut: '400px'
        },
        account: {
            type: String,
            default: ''
        }
    },
    mounted() {
        this.requests();
    },
    beforeDestroy() {
        this.$ws.off('reconnected', this.requests);
        this.$ws.off('exposure', this.onExposure);
    },
    methods: {
        requests() {
            if(!this.hasSetupListeners) {
                this.$ws.on('reconnected', this.requests);
                this.$ws.on('exposure', this.onExposure);
                this.hasSetupListeners = true;
            }

            let account = this.account;
            if(!account) {
                account = this.$store.state.activeAccount;
            }

            this.$ws.send({
                sessionID: 'NotImplemented',
                accountName: account,
                request: 'Exposure',
                args: {
                    filterType: this.type
                }
            });
        },
        onExposure(event) {
            let items = [];

            let markets = Object.keys(event.response).sort();
            let now = new Date();
            let dates = [];

            if(markets.length > 0) {
                dates = Object.keys(event.response[markets[0]]).map(function(date) {
                    return new Date(date);
                }).sort(compareAsc).map(function(date) {
                    return `${format(date, 'yyyy-MM-dd', now)}T00:00:00`;
                });
            }

            for(let market of markets) {
                let item = {
                    name: market,
                    data: [],
                    color: getColor(market)
                };

                let first = true;
                for(let date of dates) {
                    let parsedDate = new Date(date);

                    if(first) {
                        let firstDate = subBusinessDays(parsedDate, 1);
                        item.data.push([Date.UTC(firstDate.getFullYear(), firstDate.getMonth(), firstDate.getDate()), 0]);
                        first = false;
                    }

                    item.data.push([Date.UTC(parsedDate.getFullYear(), parsedDate.getMonth(), parsedDate.getDate()), parseFloat(event.response[market][date].Average) * 100]);
                }

                items.push(item);
            }

            this.chartOptions.series = items;
        },
        onExport() {
            let headers = {
                timestamp: 'Timestamp',
                market: 'Market',
                leverage: 'Leverage'
            };
            let data = [];

            let chartData = map(this.chartOptions.series, clone);
            for(let series of chartData) {
                for(let dataPoint of series.data) {
                    data.push({
                        timestamp: format(new Date(dataPoint[0]), 'yyyy-MM-dd'),
                        market: series.name,
                        leverage: dataPoint[1]
                    });
                }
            }

            exportCsv(headers, data, 'AverageLeverages');
        }
    }
}
</script>